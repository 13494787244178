import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default ({ description, lang, meta = {}, title }) => {
  const { t } = useTranslation();
  const metaDescription = description || t('siteMetadata.description'); // site.siteMetadata.description;

  // https://docs.google.com/document/d/1WukCFI7de8Fh5fOtIQHIfyDUewm1RcFXfJv9GHdLHk4/edit
  // TODO - i18n meta

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`${t('siteMetadata.title')} | %s`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: t('siteMetadata.author'),
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'fo-verify',
          content: '186e5dea-7899-47cf-8506-c1663704738e',
        },
      ].concat(meta)}
    />
  );
};
