import React from 'react';
import { useTranslation } from 'react-i18next';
import WelcomeImage from '../../images/modal-welcome.svg';
import Portal from './Portal';
import {
  ModalViewport,
  Modal,
  ModalText,
  ModaImageWrapper,
  CloseModalButton,
} from './styles';
import { SectionsTitle, FormButton } from '../styles';

export default ({ onCloseModal }) => {
  const { t } = useTranslation();

  return (
    <Portal>
      <ModalViewport onClick={onCloseModal} />
      <Modal>
        <CloseModalButton onClick={onCloseModal} />

        <SectionsTitle>{t('Welcome to oorganized')}</SectionsTitle>
        <ModaImageWrapper>
          <WelcomeImage />
        </ModaImageWrapper>
        <ModalText>{t('Modal text')}</ModalText>
        <FormButton onClick={onCloseModal}>{t('Sounds great')}</FormButton>
      </Modal>
    </Portal>
  );
};
