import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useTranslation } from 'react-i18next';
import {
  TopBarWrapper,
  TopMenu,
  TopMenuLink,
  LogoTop,
  TopBarContainer,
  Language,
  LanguageMobile,
  Hamburger,
  HamburgerButton,
  MobileMenu,
  MobileMenuLink,
  MobileMenuWrapper,
  MobileMenuItem,
} from './styles';
import localeContext from '../../localeContext';

export default () => {
  const [isMobileMenuOpen, openMobileMenu] = useState(false);
  const { t } = useTranslation();
  let { locale } = useContext(localeContext);
  locale = locale === 'en' ? '' : locale;

  const onHamburgerClick = () => {
    if (!isMobileMenuOpen) {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = '';
    }
    openMobileMenu(!isMobileMenuOpen);
  };

  return (
    <TopBarWrapper>
      <TopBarContainer>
        <AnchorLink to={`/${locale}#subscribeFormTop`} title="subscribeFormTop">
          <LogoTop />
        </AnchorLink>
        <TopMenu>
          <TopMenuLink to={`/${locale}#aboutUs`}>{t('nav.about')}</TopMenuLink>
          <TopMenuLink to={`/${locale}#benefits`}>{t('nav.benefits')}</TopMenuLink>
          <TopMenuLink to={`/${locale}#features`}>{t('nav.features')}</TopMenuLink>
          <TopMenuLink to={`/${locale}#pricing`}>{t('nav.pricing')}</TopMenuLink>
        </TopMenu>
        <Language>
          <Link to="/">EN</Link>
          <Link to="/ru">RU</Link>
          <Link to="/ar">AR</Link>
        </Language>
        {isMobileMenuOpen && (
        <MobileMenuWrapper>
          <MobileMenu>
            <MobileMenuItem to={`/${locale}#aboutUs`}>
              <MobileMenuLink onClick={onHamburgerClick}>
                {t('nav.about')}
              </MobileMenuLink>
            </MobileMenuItem>
            <MobileMenuItem to={`/${locale}#benefits`}>
              <MobileMenuLink onClick={onHamburgerClick}>
                {t('nav.benefits')}
              </MobileMenuLink>
            </MobileMenuItem>
            <MobileMenuItem to={`/${locale}#features`}>
              <MobileMenuLink onClick={onHamburgerClick}>
                {t('nav.features')}
              </MobileMenuLink>
            </MobileMenuItem>
            <MobileMenuItem to={`/${locale}#pricing`}>
              <MobileMenuLink onClick={onHamburgerClick}>
                {t('nav.pricing')}
              </MobileMenuLink>
            </MobileMenuItem>
          </MobileMenu>
          <LanguageMobile>
            <Link to="/">EN</Link>
            <Link to="/ru">RU</Link>
            <Link to="/ar">AR</Link>
          </LanguageMobile>
        </MobileMenuWrapper>
        )}
        <HamburgerButton onClick={onHamburgerClick}>
          <Hamburger className={isMobileMenuOpen && 'open'}>
            <span />
            <span />
            <span />
          </Hamburger>
        </HamburgerButton>
      </TopBarContainer>
    </TopBarWrapper>
  );
};
