import React from 'react';
import TopBar from './TopBar';
import Footer from './Footer';
import { GlobalStyle, MainLayoutWrapper } from './styles';

export default ({ children }) => (
  <MainLayoutWrapper>
    <GlobalStyle />
    <TopBar />
    {children}
    <Footer />
  </MainLayoutWrapper>
);
