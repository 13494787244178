import firebase from 'gatsby-plugin-firebase';
import generator from 'generate-password';

export default () => {};

// TODO cloud function
export const createUser = (email, locale) => {
  const password = generator.generate({ length: 20, numbers: true, symbols: true });
  return firebase.auth().createUserWithEmailAndPassword(email, password)
    .then(({ user }) => {
      const userData = {
        email: user.email,
        id: user.uid,
        locale,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      firebase.firestore().collection('users').doc(userData.id).set(userData, { merge: true });
    });
};
