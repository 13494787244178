import i18n from 'i18next';

i18n.init({
  debug: process.env.NODE_ENV === 'development',
  fallbackLng: 'en',
  keySeparator: '.',
  interpolation: { escapeValue: false },
  react: { useSuspense: false },
});

export default i18n;
