import { useEffect } from 'react';

export default function useScrollPosition() {
  let scrollPosition = typeof window !== 'undefined' ? window.scrollY : 0;

  const setPosition = (position) => {
    scrollPosition = position;
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => setPosition(window.scrollY));
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', () => setPosition(window.scrollY), true);
      }
    };
  }, []);

  return scrollPosition;
}
