import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import localeContext from '../../localeContext';
import { createUser } from '../../services/firebase';
import SubscribeTopImage from '../../images/subscribe-top.svg';
import SubscribeSuccessModal from '../Modal/SubscribeSuccess';
import validate from '../../services/validation';
import {
  SectionWrapper,
  ContentWrapper,
  ImageWrapper,
  SubscribeForm,
} from './styles';
import {
  SectionsTitle,
  SectionsSubtitle,
  Container,
  FormButton,
  EmailInput,
  EmailIconStyled,
  EmailInputWrapper,
  ErrorMessage,
} from '../styles';

export default () => {
  const [isModalOpen, openModal] = useState(false);
  const [email, onEmailChange] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();
  const { locale } = useContext(localeContext);

  const onFormSubmit = (event) => {
    event.preventDefault();

    if (validate(email)) {
      createUser(email, locale).then(() => {
        openModal(true);
        setErrorMessage('');
        onEmailChange('');
      }).catch((error) => {
        setErrorMessage(error?.message);
      });
    } else {
      setErrorMessage(t('cta.invalid'));
    }
  };

  return (
    <Container>
      <SectionWrapper>
        <ContentWrapper>
          <SectionsTitle id="subscribeFormTop">{t('cta.title1')}</SectionsTitle>
          <SectionsSubtitle>{t('cta.subtitle1')}</SectionsSubtitle>
          <SubscribeForm onSubmit={onFormSubmit}>
            <EmailInputWrapper>
              <EmailIconStyled />
              <EmailInput
                className={errorMessage && 'failed'}
                placeholder={t('cta.email')}
                aria-label={t('cta.email')}
                value={email}
                onChange={(event) => onEmailChange(event.target.value)}
              />
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </EmailInputWrapper>
            <FormButton onClick={onFormSubmit}>{t('cta.try')}</FormButton>
          </SubscribeForm>
        </ContentWrapper>
        <ImageWrapper>
          <SubscribeTopImage />
        </ImageWrapper>
      </SectionWrapper>
      {isModalOpen && <SubscribeSuccessModal onCloseModal={() => openModal(false)} />}
    </Container>
  );
};
