import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useTranslation } from 'react-i18next';
import { SectionWrapper,
  ImageWrapper,
  FeatureName,
  FeatureDescription,
  Feature,
  FeaturesWrapper,
} from './styles';
import {
  SectionsTitle,
  SectionsSubtitle,
  Container,
} from '../styles';

export default () => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      feature1: file(relativePath: { eq: "feature-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      feature2: file(relativePath: { eq: "feature-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      feature3: file(relativePath: { eq: "feature-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      feature4: file(relativePath: { eq: "feature-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      feature5: file(relativePath: { eq: "feature-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      feature6: file(relativePath: { eq: "feature-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `);

  return (
    <SectionWrapper>
      <Container id="features">
        <SectionsTitle>{t('Features')}</SectionsTitle>
        <SectionsSubtitle>
          {t('featuressubtitle')}
        </SectionsSubtitle>

        <FeaturesWrapper>
          <Feature>
            <ImageWrapper>
              <Img fluid={data.feature1.childImageSharp.fluid} />
            </ImageWrapper>
            <FeatureName>{t('List properties')}</FeatureName>
            <FeatureDescription>
              {t('List and advertise all your properties in minutes, save all needed details and documents')}
            </FeatureDescription>
          </Feature>

          <Feature>
            <ImageWrapper>
              <Img fluid={data.feature2.childImageSharp.fluid} />
            </ImageWrapper>
            <FeatureName>{t('Collect rent')}</FeatureName>
            <FeatureDescription>
              {t('Receive monthly rent and deposits and keep an eye to all your payments')}
            </FeatureDescription>
          </Feature>

          <Feature>
            <ImageWrapper>
              <Img fluid={data.feature3.childImageSharp.fluid} />
            </ImageWrapper>
            <FeatureName>{t('Calendar & Reminders')}</FeatureName>
            <FeatureDescription>
              {t('Enjoy adding events to calendar and never miss a viewing or a rent collection')}
            </FeatureDescription>
          </Feature>

          <Feature>
            <ImageWrapper>
              <Img fluid={data.feature4.childImageSharp.fluid} />
            </ImageWrapper>
            <FeatureName>{t('Chat')}</FeatureName>
            <FeatureDescription>
              {t('Stay in touch with your tenants and never loose their contacts')}
            </FeatureDescription>
          </Feature>

          <Feature>
            <ImageWrapper>
              <Img fluid={data.feature5.childImageSharp.fluid} />
            </ImageWrapper>
            <FeatureName>{t('Maintenance requests')}</FeatureName>
            <FeatureDescription>
              {t('Keep track of all maintenance requests and expenses on them')}
            </FeatureDescription>
          </Feature>

          <Feature>
            <ImageWrapper>
              <Img fluid={data.feature6.childImageSharp.fluid} />
            </ImageWrapper>
            <FeatureName>{t('Screening reports')}</FeatureName>
            <FeatureDescription>
              {t('Stay safe and get to know your tenants better')}
            </FeatureDescription>
          </Feature>
        </FeaturesWrapper>
      </Container>
    </SectionWrapper>
  );
};
