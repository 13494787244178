import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionsTitle, Container } from '../styles';
import {
  SectionWrapper,
  PricingItem,
  PricingItemHeader,
  BenefitsList,
  BenefitsListItem,
  PricingCost,
} from './styles';

export default () => {
  const { t } = useTranslation();

  return (
    <SectionWrapper>
      <Container>
        <SectionsTitle id="pricing">{t('Pricing')}</SectionsTitle>
        <PricingItem>
          <PricingItemHeader>
            <PricingCost>0</PricingCost> / {t('month')}
          </PricingItemHeader>
          <BenefitsList>
            <BenefitsListItem>
              {t('Landlords & Tenants access')}
            </BenefitsListItem>
            <BenefitsListItem>{t('Property management')}</BenefitsListItem>
            <BenefitsListItem>{t('Unlimited rental units')}</BenefitsListItem>
            <BenefitsListItem>{t('Unlimited storage')}</BenefitsListItem>
            <BenefitsListItem>{t('Payments')}</BenefitsListItem>
            <BenefitsListItem>{t('Property advertising')}</BenefitsListItem>
            <BenefitsListItem>{t('Online applicant form')}</BenefitsListItem>
            <BenefitsListItem>{t('Tenant screening')}</BenefitsListItem>
            <BenefitsListItem>{t('Maintenance tracking')}</BenefitsListItem>
            <BenefitsListItem>{t('Chat with tenants')}</BenefitsListItem>
            <BenefitsListItem>{t('Reminders')}</BenefitsListItem>
          </BenefitsList>
        </PricingItem>
      </Container>
    </SectionWrapper>
  );
};
