import React, { useEffect, useContext } from 'react';
import SEO from '../components/seo';
import withI18next from '../i18n/withI18next';

import MainLayout from '../components/layout';
import SubscribeFormTop from '../components/SubscribeFormTop';
import SubscribeFormBottom from '../components/SubscribeFormBottom';
import Features from '../components/Features';
import AboutUs from '../components/AboutUs';
import WhyChooseUs from '../components/WhyChooseUs';
import Pricing from '../components/Pricing';
import useScrollPosition from '../components/useScrollPosition';
import localeContext from '../localeContext';

const Index = () => {
  const { locale } = useContext(localeContext);
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, scrollPosition);
    }
  }, [locale]);

  return (
    <>
      <SEO title="Home" lang={locale} />
      <div dir={locale === 'ar' ? 'rtl' : ''}>
        <MainLayout>
          <SubscribeFormTop />
          <AboutUs />
          <WhyChooseUs />
          <Features />
          <Pricing />
          <SubscribeFormBottom />
        </MainLayout>
      </div>
    </>
  );
};

export default withI18next(Index);
