import React from 'react';
import { useTranslation } from 'react-i18next';
import AboutUsImage from '../../images/about-us.svg';
import { SectionWrapper, ContentWrapper, ImageWrapper, Text } from './styles';
import { SectionsTitle, SectionsSubtitle, Container } from '../styles';

export default () => {
  const { t } = useTranslation();

  return (
    <Container>
      <SectionWrapper>
        <ContentWrapper>
          <SectionsTitle id="aboutUs">{t('about.title')}</SectionsTitle>
          <SectionsSubtitle>{t('about.Oorganized is a free property management platform for the global market')}</SectionsSubtitle>
          <Text>{t('about.About us section description')}</Text>
        </ContentWrapper>
        <ImageWrapper>
          <AboutUsImage />
        </ImageWrapper>
      </SectionWrapper>
    </Container>
  );
};
