import React, { useContext } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useTranslation } from 'react-i18next';
import { Container } from '../styles';
import {
  FooterWrapper,
  LogoFooter,
  SectionWrapper,
  FooterMenu,
  FooterMenuLink,
  Copyright,
} from './styles';
import localeContext from '../../localeContext';

export default () => {
  const { t } = useTranslation();
  let { locale } = useContext(localeContext);
  locale = locale === 'en' ? '' : locale;

  return (
    <FooterWrapper>
      <Container>
        <SectionWrapper>
          <AnchorLink to={`/${locale}#subscribeFormTop`} title="subscribeFormTop">
            <LogoFooter />
          </AnchorLink>
          <FooterMenu>
            <FooterMenuLink to={`/${locale}#aboutUs`}>{t('nav.about')}</FooterMenuLink>
            <FooterMenuLink to={`/${locale}#benefits`}>{t('nav.benefits')}</FooterMenuLink>
            <FooterMenuLink to={`/${locale}#features`}>{t('nav.features')}</FooterMenuLink>
            <FooterMenuLink to={`/${locale}#pricing`}>{t('nav.pricing')}</FooterMenuLink>
          </FooterMenu>
        </SectionWrapper>
        <Copyright>
          © Oorganized. {t('All Rights Reserved')} {new Date().getFullYear()}.
        </Copyright>
      </Container>
    </FooterWrapper>
  );
};
